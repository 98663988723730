<template>
  <div class="imgBox">
    <img :src="imgUrl" alt="" style="display: block;width: 100%;">
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      userInfo: [],
      headers: null,
      imgData:null,
      imgUrl:'',
    };
  },
  created(){
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    };
  },   
  mounted() {
    
  },
  methods: {
    // 给imgurl赋值
    setImgUrl(){
      for(let key in this.imgData){
        if(key.indexOf(this.$i18n.locale) != -1){
          this.imgUrl = this.imgData[key].img;
          document.title = this.imgData[key].title;
          break;
        } else {
          if(this.imgData[key].default == 1){
            this.imgUrl = this.imgData[key].img;
            document.title = this.imgData[key].title;
          }
        }
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' ) {
          this.$i18n.locale = 'zh_TW'
        }else if(lang == 'zh_HK'){
          this.$i18n.locale = 'zh_HK'
        }else {
          this.$i18n.locale = 'zh_CN'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      this.getImageInfo();
    },
    //获取图片资料
    getImageInfo() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/storage/getMediaPage?token=' + that.token  + '&uid=' + that.uid + '&mediaId=' + that.$route.query.id,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.imgData = JSON.parse( data.data.config)
          this.setImgUrl()
        }
      })
    },
  },
};
</script>

<style scoped>
* {
  margin: 0!important;
  padding: 0!important;
}
.imgBox{
  width: 6.4rem;
  position: absolute;
  left: 0;
}
</style>
